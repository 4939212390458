@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
    box-sizing: border-box;
}

.container {
    display: flex;
    width: 1040px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.card {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
}

.cardHeader img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
}

.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0 0 10px -10px;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.tagTeal {
    background-color: #47bcd4;
}

.tagPurple {
    background-color: #5e76bf;
}

.tagPink {
    background-color: #cd5b9f;
}

.cardBody p {
    font-size: 13px;
    margin: 0 0 40px;
}

.cardBody h4{
    margin: 10px 0px;
    font-family: "Roboto", sans-serif;
    color: #10182f;
    font-weight: bold;
}

.user {
    display: flex;
    margin-top: auto;
}

.user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.userInfo h5 {
    margin: 0;
    font-weight: 400;
}

.userInfo small {
    color: #545d7a;
}

@media screen and (max-width: 1050px){
    .container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .card{
        width: 100%;
    }

    .cardBody{
        height: 200px;
        min-height: 200px;
    }

    .cardHeader{
        height: 200px;
    } 

    .cardBody p {
        font-size: 15px;
    }

    .cardBody h4{
        font-size: 20px;
    }
    .tag{
        font-size: 14px;
        margin: 0;
    }
    .userInfo h5 {
        font-size: 15px;
    }

    .userInfo small{
        font-size: 12px;
    }
}

@media screen and (max-width: 800px){
    .container{
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .card{
        width: 100%;
        height: 380px;
        margin: 10px 0;
    }

    .cardBody{
        width: 100%;
    }

    .cardHeader{
        height: 150px;
    } 

    .cardHeader img {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .cardBody p {
        font-size: 15px;
    }

    .cardBody h4{
        font-size: 20px;
    }
    .tag{
        font-size: 14px;
        margin-top: 15px;
    }
    .userInfo h5 {
        font-size: 15px;
    }

    .userInfo small{
        font-size: 12px;
    }
}