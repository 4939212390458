@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100&display=swap');

.bodyInfo{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title{
    margin: 30px 0 30px 0;
    font-size: 50px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
}

@media (max-width: 600px){
    .title{
        font-size: 40px;
    }
}