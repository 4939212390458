.App {
  text-align: center;
  width: 1800px;
  margin: 0 auto;
}

a{
  outline: none;
  text-decoration: none;
}

Slidenavbar {
  position: relative;
  z-index: 0;
}
