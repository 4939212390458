.gallery {
    width: 100%;
    display: inline-flex;
    transition: 0.3s;
}

.firtGroup{
    display:flex;
    width: 100%;
    transition: 0.3s;
}
.secondGroup{
    display:flex;
    width: 100%;
    transition: 0.3s;
}

.gallery:hover .galleryImage {
    filter: grayscale(1);
}

.galleryColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.3s;
}

.galleryLink {
    margin: 1px;
    overflow: hidden;
}

.galleryLink:hover .galleryImage {
    filter: grayscale(0);
}

.galleryLink:hover .galleryCaption {
    opacity: 1;
}

.galleryThumb {
    position: relative;
}

.galleryImage {
    display: block;
    width: 100%;
    transition: 0.3s;
}

.galleryImage:hover {
    transform: scale(1.1);
}

.galleryCaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px 15px 15px;
    width: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: white;
    opacity: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    transition: 0.3s;
}

@media screen and (max-width: 800px){
    .gallery {
        width: 100%;
        display: flex;
        transition: 0.3s;
    }

    .galleryColumn {
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: 0.3s;
    }
    .firtGroup{
        display:block;
        transition: 0.3s;
    }
    .secondGroup{
        display:block;
        transition: 0.3s;
    }
}

