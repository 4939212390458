@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100&display=swap');

nav a {
    color: white;
}

nav a:hover {
    color: black;
}

nav img {
    height: 90px;
    width: 380px;
    margin: 15px 0 0 15px;
    transition: 0.4s;
    /* border-radius: 10px;
    box-shadow: 0px 3px 10px 0px black; */
}

nav img:hover {
    transform: scale(1.05);
}

a {
    outline: none;
    text-decoration: none;
}

@media screen and (max-width: 1200px) {
    nav img {
        height: 70px;
        width: 300px;
        margin-top: 25px;
    }

    .navContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.4s;
    }

    .menuBar {
        display: inline-flex;
    }
}

.navContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    padding-top: 30px;
    z-index: 1;
}

.slide {
    position: relative;
    z-index: 0;
}

.menuBar {
    border-radius: 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: inline-flex;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    align-items: center;
    padding: 0 10px;
    margin: 30px 30px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.568);
}

.menuBar li {
    list-style: none;
    font-family: sans-serif;
    font-weight: bold;
    padding: 12px 16px;
    margin: 0 8px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}

.menuBar li::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: 0.2s;
    border-radius: 25px;
}

.menuBar li:hover {
    color: black;
}

.menuBar li:hover::before {
    background: linear-gradient(to bottom, #e8edec, #d2d1d3);
    box-shadow: 0px 3px 20px 0px black;
    transform: scale(1.2);
}

@media screen and (max-width: 800px) {
    nav img {
        height: 70px;
        width: 300px;
        margin-top: 50px;
    }

    .navContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.4s;
        padding: 30px 0;
    }

    .menuBar {
        width: 400px;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        padding: 0;
    }

    /* .menuBar a:nth-child(1){
        display: none;
    } */

    .menuBar a {
        width: 100%;
        text-align: center;
        margin: 10px 0;
        font-style: italic;
        font-family: 'Roboto', sans-serif;
    }

    .menuBar li:hover::before {
        background: linear-gradient(to bottom, #e8edec, #d2d1d3);
        box-shadow: 0px 3px 20px 0px black;
        transform: scale(1.2);
    }

    .list {
        /* margin: 2rem; */
        text-decoration: none;
        font-size: 14px;
        font-style: normal;
        font-family: 'Roboto', sans-serif;
        position: relative;
    }

    .menuBar li {
        padding: 10px 0px;
        
    }

    .respmenu{
        width: 95%;
        margin: 0;
        padding: 0;
        text-align: center
    }

}