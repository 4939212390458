
.proyectosContainer{
    margin: 20px 0px ;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    margin: 30px 0 50px 0;
    font-size: 50px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    
}

.descripcionProyectos{
    width: 60%;
    margin-bottom: 50px;
    font-size: 30px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
}

@media (max-width: 600px){
    .title{
        font-size: 40px;
    }

    .descripcionProyectos{
        font-size: 20px;
    }
}