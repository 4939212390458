
footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    background: #485563;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #29323c, #485563); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
footer h2{
    margin: 0;
    font-size: 20px;
}

footer p{
    margin-top: 5px;
    color: whitesmoke;
    font-size: 15px;
}

footer a{
    color: black;
    margin-top: 30px;
}

.footerContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

footer img{
    width: 180px;
    height: 50px;
    margin-bottom: 80px;
    transition: 0.2s;
}

footer img:hover{
    transform: scale(1.15);
}

.info{
    width: 200px;
    margin-top: 15px;
}

.socialmedia{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 1;
}

.logo{
    display: flex;
}

.wrapper{
    display:inline-flex;
    margin-bottom: 15px;
}

.wrapper .icon{
    margin: 0 15px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    position: relative;
    z-index:2;
}

.wrapper .icon span{
    position:relative;
    z-index:2;
    height: 50px;
    width: 50px;
    display:block;
    background: #fff;
    box-shadow: 0 10px 10px rgba(0,0,0,0.4);
    border-radius:50%;
    text-align:center;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon span .i{
    font-size:20px;
    margin-top: 15px;
    line-height:60px;
}

.wrapper .icon .tooltip{
    position:absolute;
    top:0px;
    background: #fff;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    font-size: 20px;
    padding: 10px 18px;
    border-radius: 25px;
    color:white;
    opacity:0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip{
    opacity:1;
    pointer-events: auto;
    top:-70px;
}
.wrapper .icon .tooltip:before{
    position:absolute;
    content:"";
    height:15px;
    width:15px;
    bottom:-8px;
    left:50%;
    transform: translateX(-50%) rotate(45deg);
}
.wrapper .icon:hover span,
.wrapp .icon:hover .tooltip{
    text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}
.wrapper .icon:hover span{
    color: #fff;
}
.wrapper .facebook:hover span,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip:before{
    background:#3B5999;
}
.wrapper .instagram:hover span,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip:before{
    background: #de463b;
}
.wrapper .tiktok:hover span,
.wrapper .tiktok:hover .tooltip,
.wrapper .tiktok:hover .tooltip:before{
    background:#8565C4;
}

footer a p{
    display: none;
}

@media screen and (max-width: 800px){
    .footerContainer{
        display: flex;
        flex-wrap: wrap;

    }
    .logo img{
        margin: 0 0 10px 0;
    }

    .logo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .wrapper .icon span{
        height: 35px;
        width: 35px;
    }
    
    .wrapper .icon span .i{
        font-size:15px;
        margin-top: 10px;
    }

    .wrapper a{
        margin: -5px;
    }

    footer .socialmedia p{
        display: none;
    }

    footer a p{
        display: block;
        margin-bottom: 10px;
    }
}