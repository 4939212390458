
.wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.slideshow {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
}

.slideshowHero {
    z-index: 3;
    left: 0;
    top: 50%;
    height: 80%;
    transform: translateY(-50%) skewY(-10deg);
    transform-origin: center;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
}

.slideshowHero .slides {
    position: absolute;
    left: 0;
    top: -10%;
    width: 100vw;
    height: 100vh;
    animation: 11s slideshow-hero-mobile -3s infinite;
}

.slideshowHero .slide1 {
    background-image: url('../../source/slide1.jpg');
    animation: 20s slides-set-1 -0.1s infinite;
}

.slideshowHero .slide2 {
    background-image: url('../../source/slide2.jpg');
    animation: 20s slides-set-2 -0.1s infinite;
}

.slideshowHero .slide3 {
    background-image: url('../../source/slide3.jpg');
    animation: 20s slides-set-3 -0.1s infinite;
}

@media (min-width: 600px) {
    .slideshowHero {
        left: 50%;
        top: 0;
        width: 67%;
        height: 100%;
        transform: translateX(-50%) skewX(-10deg);
    }

    .slideshowHero .slides {
        top: 0;
        left: -25%;
        height: 100%;
        animation: 11s slideshow-hero -3s infinite;
    }
    
}

.slideshowContrast {
    z-index: 1;
    width: 100%;
    height: 80%;
    transform: none;
}

.slideshowContrastBefore {
    left: 0;
}

.slideshowContrastBefore .slides {
    width: 100vw;
}

.slideshowContrastAfter {
    z-index: 2;
    left: auto;
    right: 0;
}

.slideshowContrastAfter .slides {
    width: 100vw;
    left: auto;
    right: 0;
}

.slideshowContrast .slides {
    animation: 14s slideshow-contrast -5s infinite;
}

.slideshowContrast .slide1 {
    background-image: linear-gradient(to bottom, rgba(0,110,152, 0.25) 0, rgba(159,167,166, 0.5) 100%), url('../../source/slide1.jpg');
    animation: 20s slides-set-1 -0.2s infinite;
}

.slideshowContrast .slide2 {
    background-image: linear-gradient(to bottom, rgba(0,110,152, 0.25) 0, rgba(159,167,166, 0.5) 100%), url('../../source/slide2.jpg');
    animation: 20s slides-set-2 -0.2s infinite;
}

.slideshowContrast .slide3 {
    background-image: linear-gradient(to bottom, rgba(0,110,152, 0.25) 0, rgba(159,167,166, 0.5) 100%), url('../../source/slide3.jpg');
    animation: 20s slides-set-3 -0.2s infinite;
}

.slideshowContrastAfter {
    top: auto;
    bottom: 0;
}

.slideshowContrastAfter .slides {
    animation: 13s slideshow-contrast -13s infinite;
}

.slideshowContrastAfter .slide {
    background-position: right;
}

.slideshowContrastAfter .slide1 {
    animation: 20s slides-set-1 infinite;
}

.slideshowContrastAfter .slide2 {
    animation: 20s slides-set-2 infinite;
}

.slideshowContrastAfter .slide3 {
    animation: 20s slides-set-3 infinite;
}

@media (min-width: 600px) {
    .slideshowContrast {
        width: 50%;
        height: 100%;
    }

    .slideshowContrastAfter {
        top: 0;
        bottom: auto;
    }

    .slideshowContrastAfter .slides {
        width: 50vw;
    }
}

.slides,
.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@keyframes slideshow-hero-mobile {
    0% {
        transform: scale(1) skewY(10deg);
    }

    50% {
        transform: scale(1.05) skewY(10deg);
    }

    100% {
        transform: scale(1) skewY(10deg);
    }
}

@keyframes slideshow-hero {
    0% {
        transform: scale(1) skewX(10deg);
    }

    50% {
        transform: scale(1.05) skewX(10deg);
    }

    100% {
        transform: scale(1) skewX(10deg);
    }
}

@keyframes slideshow-contrast {
    0% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
    }
}

@keyframes slides-set-1 {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    31% {
        opacity: 1;
        transform: scale(1);
    }

    34% {
        opacity: 0;
        transform: scale(1.05);
    }

    97% {
        opacity: 0;
        transform: scale(1.05);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slides-set-2 {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }

    31% {
        opacity: 0;
        transform: scale(1.05);
    }

    34% {
        opacity: 1;
        transform: scale(1);
    }

    64% {
        opacity: 1;
        transform: scale(1);
    }

    67% {
        opacity: 0;
        transform: scale(1.05);
    }

    100% {
        opacity: 0;
        transform: scale(1.05);
    }
}

@keyframes slides-set-3 {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }

    64% {
        opacity: 0;
        transform: scale(1.05);
    }

    67% {
        opacity: 1;
        transform: scale(1);
    }

    97% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.05);
    }
}