@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700,800");

* {
    box-sizing: border-box;
    margin: 0;
}

html,
body {
    margin: 0;
    background: white;
    font-family: "Montserrat", helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.link {
    display: block;
    text-align: center;
    color: #777;
    text-decoration: none;
    padding: 10px;
}

.movieCard {
    position: relative;
    display: block;
    width: 650px;
    height: 350px;
    margin: 30px auto;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.4s;
    box-shadow: 0px 0px 120px -25px rgba(0, 0, 0, 0.5);
}

.movieCard:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 80px -25px rgba(0, 0, 0, 0.5);
    transition: all 0.4s;
}

.movieCard .infoSection {
    position: relative;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    z-index: 2;
    border-radius: 10px;
}

.movieCard .infoSection .movieHeader {
    position: relative;
    padding: 25px;
    height: 40%;
}

.movieCard .infoSection .movieHeader h1 {
    color: black;
    font-weight: 400;
}

.movieCard .infoSection .movieHeader h4 {
    color: #555;
    font-weight: 400;
}

.movieCard .infoSection .movieHeader .minutes {
    display: inline-block;
    margin-top: 15px;
    color: #555;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.movieCard .infoSection .movieHeader .type {
    display: inline-block;
    color: #959595;
    margin-left: 10px;
}

.movieCard .infoSection .movieHeader .locandina {
    position: relative;
    float: left;
    margin-right: 20px;
    height: 120px;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
}

.movieCard .infoSection .movieDesc {
    padding: 25px;
    height: 50%;
}

.movieCard .infoSection .movieDesc .text {
    color: #545454;
}


.movieCard .blurBack {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    right: 0;
    background-size: cover;
    border-radius: 11px;
}

@media screen and (min-width: 768px) {
    .movieHeader {
        width: 65%;
    }

    .movieDesc {
        width: 50%;
    }

    .infoSection {
        background: linear-gradient(to right, #e5e6e6 50%, transparent 100%);
    }

    .blurBack {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .movieCard {
        width: 95%;
        margin: 70px auto;
        min-height: 350px;
        height: auto;
    }

    .blurBack {
        width: 100%;
        background-position: 50% 50% !important;
    }

    .movieHeader {
        width: 100%;
        margin-top: 85px;
    }

    .movieDesc {
        width: 100%;
    }

    .infoSection {
        background: linear-gradient(to top, #e5e6e6 50%, transparent 100%);
        display: inline-grid;
    }
}

.brightBack {
    background: url("https://occ-0-2433-448.1.nflxso.net/art/cd5c9/3e192edf2027c536e25bb5d3b6ac93ced77cd5c9.jpg");
}

.tombBack {
    background: url("https://fsmedia.imgix.net/cd/c9/5e/ba/4817/4d9a/93f0/c776ec32ecbc/lara-crofts-neck-looks-unnatural-in-the-new-poster-for-tomb-raider.png");
}

.aveBack {
    background: url("https://www.gannett-cdn.com/-mm-/c03fd140debe8ad4c05cf81a5cad7ad61a12ce52/c=0-1580-2985-3266&r=x803&c=1600x800/local/-/media/2017/06/09/USATODAY/USATODAY/636326272873599176-Black-Panther-Teaser.jpg");
}

.title{
    margin: 30px 0 50px 0;
    font-size: 50px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    
}

.descripcionProyectos{
    width: 60%;
    margin-bottom: 50px;
    font-size: 30px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
}

.movieConteiner{
    margin: 5px 0px ;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 768px){
    .title{
        font-size: 40px;
    }

    .descripcionProyectos{
        font-size: 20px;
    }
}

.textoConteiner{
    display: flex;
    flex-direction: column;
    align-items: center;
}